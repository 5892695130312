import { useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';

import { TextField } from '@mui/material';

import Text from 'src/components/Text';
import { useLanguage } from 'src/contexts/language.context';
import { useAuth } from 'src/contexts/auth.context';
import useStore from 'src/store';
import { EventFormValues } from '../EventForm/EventForm';
import { Map } from './Map';

export interface Coordinates {
  latitud: number;
  longitud: number;
}

interface EventMapProps {
  values: EventFormValues;
  handleInputChange: (field: string, value: any) => void;
}

export default function EventMap({ values, handleInputChange }: EventMapProps) {
  const { location, place } = values;

  const auth = useAuth();
  const showMessage = useStore.use.showMessage();
  const { getTranslation } = useLanguage();
  const [locationCoordenates, setLocationCoordenates] = useState<Coordinates>({
    latitud: -34.891461,
    longitud: -56.153249,
  });
  const [showMap, setShowMap] = useState(!!location);

  function getUserLocation() {
    function success(pos: any) {
      const crd = pos.coords;

      setLocationCoordenates({ latitud: crd.latitude, longitud: crd.longitude });
    }

    function error(err: any) {
      showMessage('GEOLOCATION_DENIED_AND_NO_LOCATION', 'error');
    }

    navigator.geolocation.getCurrentPosition(success, error);
  }

  async function getAdress(latToGeocode: any, lngToGeocode: any) {
    latToGeocode = parseFloat(latToGeocode);
    lngToGeocode = parseFloat(lngToGeocode);

    if (latToGeocode >= -90 && latToGeocode <= 90 && lngToGeocode >= -180 && lngToGeocode <= 180) {
      const latlngUrl =
        'https://api.opencagedata.com/geocode/v1/json?q=' +
        latToGeocode +
        '+' +
        lngToGeocode +
        `&key=babfe90dfa714cbc984af4812632aa33&no_annotations=1&language=${auth?.currentUser?.language}`;

      const response = await fetch(latlngUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const adressData = await response.json();

      handleInputChange('location', adressData.results[0].formatted);
      setLocationCoordenates({ latitud: latToGeocode, longitud: lngToGeocode });
    } else {
      alert('Invalid coordinates');
    }
  }

  async function getLatLong(addr: string) {
    const url =
      'https://api.opencagedata.com/geocode/v1/json?q=' +
      encodeURIComponent(addr) +
      `&key=babfe90dfa714cbc984af4812632aa33&no_annotations=1&language=${auth?.currentUser?.language}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    const data = await response.json();

    if (data.status.code === 200) {
      if (data.total_results >= 1) {
        var latres = data.results[0].geometry.lat;
        var lngres = data.results[0].geometry.lng;
        setLocationCoordenates({ latitud: latres, longitud: lngres });
      } else {
        alert('textGeocodingFail');
      }
    } else {
      alert('textGeocodingFail');
    }
  }

  const handleShowMapToogle = (value: boolean) => {
    if (!value) {
      handleInputChange('location', null);
    }
    setShowMap(value);
  };

  useEffect(() => {
    if (showMap && !location) {
      getUserLocation();
    }
  }, [location, showMap]);

  return (
    <div>
      <TextField
        type="text"
        name="place"
        id="place-input"
        className="w-full mt-5 mb-5"
        value={place}
        onChange={e => {
          handleInputChange('place', e.target.value);
        }}
        label={<Text simple>PLACE</Text>}
      />

      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => handleShowMapToogle(!showMap)}
          className="rounded-md text-gray-200 bg-blue-500 p-4"
        >
          {showMap ? getTranslation('HIDE_MAP_PINPOINT') : getTranslation('PINPOINT_ON_MAP')}
        </button>
      </div>

      {showMap && (
        <>
          <TextField
            type="text"
            name="location"
            id="location-input"
            className="w-full mt-5 mb-5"
            value={location}
            onChange={e => {
              handleInputChange('location', e.target.value);
            }}
            label={<Text simple>LOCATION</Text>}
            helperText={getTranslation('HELPER_LOCATION')}
          />

          <button
            type="button"
            onClick={() => {
              values.location ? getLatLong(values.location) : getUserLocation();
            }}
            className="rounded-md text-gray-200 bg-blue-500 p-2 mb-3"
          >
            {getTranslation('LOCATE')}
          </button>
          <Map getAdress={getAdress} locationCoordenates={locationCoordenates} eventName={values.name} />
        </>
      )}
    </div>
  );
}
