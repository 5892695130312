import { useEffect, useMemo, useState } from 'react';
import { Button, MenuItem, TextField, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Check, Delete, ExitToApp, CloudUploadRounded } from '@mui/icons-material';
import DefaultImage from 'src/assets/images/events/default.png';

import SEO from 'src/components/SEO/SEO';
import Text from 'src/components/Text';

import { UserItem } from 'src/types/user.type';
import { getPricing } from 'src/utils/getPricing';
import { useAuth } from 'src/contexts/auth.context';
import { useLanguage } from 'src/contexts/language.context';
import { useUserContext } from 'src/contexts/users.context';
import { useCompanyContext } from 'src/contexts/company.context';
import { ICompany, ISubscription } from 'src/types/company.type';
import { getCompany } from 'src/services/database/company.service';
import { getUser, removeUserCompany } from 'src/services/database/user.service';
import useStore from 'src/store';
import ModalSuggestions from './ModalSuggestions';
import Languages from 'src/constants/languages';
import system from 'src/constants/system';
import { AvailableTickets } from './AvailableTickets/AvailableTickets';
import { CompanyKeys } from './CompanyKeys/CompanyKeys';
import { DropzoneDialog } from 'react-mui-dropzone';
import { uploadCompanyImage } from 'src/services/storage/company.service';

export const ILIMITED_EVENTS = 17;
export const ILIMITED_ACCESSES = 900;

function Settings() {
  const auth = useAuth();
  const isAdmin = auth?.currentUser?.isAdmin ?? false;
  const showMessage = useStore.use.showMessage();
  const showLoading = useStore.use.showLoading();
  const dismissLoading = useStore.use.dismissLoading();
  const userContext = useUserContext();
  const { getTranslation, changeLanguage, language } = useLanguage();
  const companyContext = useCompanyContext();

  const navigate = useNavigate();

  const [user, setUser] = useState<UserItem | null>(null);
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');

  const [isModalSuggestionsOpen, setIsModalSuggestionsOpen] = useState<boolean>(false);

  const [company, setCompany] = useState<ICompany | null>(null);
  const [companyFormValues, setCompanyFormValues] = useState<ICompany>({
    name: '',
    country: '',
    image: '',
    language: Languages.es,
    description: '',
    socialLinks: {
      facebook: '',
      twitter: '',
      instagram: '',
    },
    id: '',
  });
  const [addModal, setAddModal] = useState<boolean>(false);

  const [subscription, setSubscription] = useState<ISubscription | null>(null);

  const [subscriptionEvents, setSubscriptionEvents] = useState(3);
  const [subscriptionAccesses, setSubscriptionAccesses] = useState(100);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.currentUser, auth?.currentCompany]);

  const hasChange = useMemo(() => user?.firstName !== name || user?.lastName !== lastname, [user, name, lastname]);
  const hasCompanyChange = useMemo(
    () =>
      company?.name !== companyFormValues.name ||
      company?.country !== companyFormValues.country ||
      company?.image !== companyFormValues.image ||
      company?.language !== companyFormValues.language ||
      company?.description !== companyFormValues.description ||
      company?.showOnlyBanner !== companyFormValues.showOnlyBanner ||
      company?.socialLinks !== companyFormValues.socialLinks,
    [company, companyFormValues],
  );

  const getData = async () => {
    if (!auth?.currentUser?.uid) return null;
    const user = await getUser(auth.currentUser.uid);
    setUser(user);
    setName(user?.firstName || '');
    setLastname(user?.lastName || '');
    const company = await getCompany(auth?.currentCompany?.id || '');
    if (company) {
      setCompany(company);
      setCompanyFormValues({
        name: company.name,
        country: company.country ?? '',
        image: company.image ?? '',
        language: company.language ?? Languages.es,
        description: company.description ?? '',
        socialLinks: {
          facebook: company.socialLinks?.facebook ?? '',
          twitter: company.socialLinks?.twitter ?? '',
          instagram: company.socialLinks?.instagram ?? '',
        },
        id: '',
      });
    }
    setSubscription(company?.subscription || null);
    setSubscriptionEvents(company?.subscription?.events || 3);
    setSubscriptionAccesses(company?.subscription?.accesses || 100);
  };

  const onSubmitProfile = async () => {
    if (!hasChange) return;
    showLoading();
    const result = await userContext?.updateProfile(name, lastname);
    if (result && user) {
      setUser({ ...user, firstName: name.trim(), lastName: lastname.trim() });
      showMessage('SUCCESS_UPDATE', 'success');
    } else {
      showMessage('FAILED_UPDATE', 'error');
    }
    dismissLoading();
  };

  const onSubmitCompany = async () => {
    if (!hasCompanyChange || !company) return;
    showLoading();

    const result = await companyContext?.updateCompany({
      name: companyFormValues.name,
      country: companyFormValues.country,
      image: companyFormValues.image,
      socialLinks: companyFormValues.socialLinks,
      language: companyFormValues.language,
      description: companyFormValues.description,
      showOnlyBanner: companyFormValues.showOnlyBanner,
      id: company.id,
    });

    if (result && company) {
      setCompany(companyFormValues);
      auth?.switchCompany(company);
      showMessage('SUCCESS_UPDATE', 'success');
    } else {
      showMessage('FAILED_UPDATE', 'error');
    }
    dismissLoading();
  };

  const onDeleteCompany = () => {
    // TODO: Delete company from `/companies` and remove company from all users
    showMessage('METHOD_NOT_IMPLEMENTED', 'info');
  };

  const onLoadImage = async (e: any[]) => {
    if (!company) return;
    const url = await uploadCompanyImage(company?.id, e[0], auth?.currentUser?.uid as string);
    const timestamp = new Date().getTime();
    setCompanyFormValues(current => ({ ...current, image: url + '&' + timestamp }));

    setAddModal(false);
  };

  const onExitCompany = async () => {
    if (!auth || !auth.currentUser || !company) return;
    const response = await removeUserCompany(company.id, auth.currentUser.uid);
    if (response) showMessage('EXIT_COMPANY_SUCCESS', 'success');
    else showMessage('EXIT_COMPANY_ERROR', 'error');
    const newCompany = auth.currentUser.companies?.find(item => item.id !== company.id);
    if (newCompany) {
      auth?.switchCompany(newCompany);
      return navigate('/dashboard');
    }
    auth.setCurrentCompany(null);
    navigate('/dashboard/new-company');
  };

  const handleOnChangeCompanyValues = (field: string, value: any) => {
    setCompanyFormValues(current => ({ ...current, [field]: value }));
  };

  const handleSocialLinkChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, social: string) => {
    setCompanyFormValues(current => ({
      ...current,
      socialLinks: {
        ...current.socialLinks,
        [social]: e.target.value,
      },
    }));
  };

  return (
    <>
      <SEO
        title={'QR Event Manager - ' + getTranslation('SETTINGS')}
        description={getTranslation('A_USERS_SUGGESTION_1')}
        keywords="qr, event, tickets"
      />
      <div className="pb-10">
        <Text className="text-3xl mb-5 text-center md:text-left" bold>
          SETTINGS
        </Text>

        <div className="bg-blue-400 text-white text-sx title-font font-medium rounded-md mb-4 p-2">
          {getTranslation('TIPS')}
          <button
            onClick={() => setIsModalSuggestionsOpen(true)}
            className="hover:text-blue-100 cursor-pointer ease-in-out text-blue-800"
          >
            {getTranslation('HERE')}.
          </button>
        </div>

        <section className="bg-white p-5 rounded-lg shadow-md">
          <Text bold>MY_PROFILE</Text>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-3">
            <div>
              <Text className="mb-1">EMAIL</Text>
              <input
                disabled
                type="email"
                value={auth?.currentUser?.email || ''}
                className="w-full border-[1px] border-gray-600 rounded-md px-3 py-2 cursor-not-allowed"
              />
            </div>
            <div>
              <Text className="mb-1">LANGUAGE</Text>
              <TextField
                type="text"
                name="eventLanguage"
                id="event-language"
                value={language}
                onChange={e => e.target.value && changeLanguage(e.target.value as Languages)}
                className="w-full rounded-md pb-2 border-none"
                SelectProps={{
                  className: '!border-[1px] !border-gray-600',
                }}
                inputProps={{
                  className: '!py-[9px] border-none',
                }}
                InputProps={{
                  classes: {
                    notchedOutline: '!border-none',
                  },
                }}
                select
              >
                <MenuItem value={Languages.es}>
                  <Text>SPANISH</Text>
                </MenuItem>
                <MenuItem value={Languages.en}>
                  <Text>ENGLISH</Text>
                </MenuItem>
              </TextField>
            </div>
            <div>
              <Text className="mb-1">FIRST_NAME</Text>
              <input
                value={name}
                placeholder={getTranslation('YOUR_FIRST_NAME')}
                onChange={({ target: { value } }) => setName(value)}
                className="w-full border-[1px] border-gray-600 rounded-md px-3 py-2"
              />
            </div>
            <div>
              <Text className="mb-1">LAST_NAME</Text>
              <input
                value={lastname}
                placeholder={getTranslation('YOUR_LAST_NAME')}
                onChange={({ target: { value } }) => setLastname(value)}
                className="w-full border-[1px] border-gray-600 rounded-md px-3 py-2"
              />
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <button
              onClick={onSubmitProfile}
              className={`${hasChange ? 'bg-green-600' : 'bg-gray-400'} px-3 py-1 rounded-md flex flex-row`}
            >
              <Text className="font-semibold" color="text-white">
                SAVE
              </Text>
              <Check className="text-white" />
            </button>
          </div>
        </section>

        <section className="bg-white p-5 rounded-lg shadow-md mt-5">
          <Text bold>COMPANY</Text>
          <div className="flex flex-col gap-8 mt-3">
            <div className="flex flex-col md:flex-row gap-5">
              <div className="flex-1">
                <Text className="mb-1">NAME</Text>
                <input
                  value={companyFormValues.name}
                  placeholder={getTranslation('COMPANY_NAME')}
                  onChange={({ target: { value } }) => handleOnChangeCompanyValues('name', value)}
                  className="w-full border-[1px] border-gray-600 rounded-md px-3 py-2"
                />
              </div>
              <div className="flex-1">
                <Text className="mb-1">COUNTRY</Text>
                <input
                  value={companyFormValues.country}
                  placeholder={getTranslation('COMPANY_COUNTRY')}
                  onChange={({ target: { value } }) => handleOnChangeCompanyValues('country', value)}
                  className="w-full border-[1px] border-gray-600 rounded-md px-3 py-2"
                />
              </div>
              <div className="flex-1">
                <Text className="mb-1">COMPANY_LANGUAGE</Text>
                <TextField
                  type="text"
                  name="eventLanguage"
                  id="event-language"
                  value={companyFormValues.language || Languages.es}
                  onChange={({ target: { value } }) => handleOnChangeCompanyValues('language', value)}
                  className="w-full rounded-md pb-2 border-none"
                  SelectProps={{
                    className: '!border-[1px] !border-gray-600',
                  }}
                  inputProps={{
                    className: '!py-[9px] border-none',
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: '!border-none',
                    },
                  }}
                  select
                >
                  <MenuItem value={Languages.es}>
                    <Text>SPANISH</Text>
                  </MenuItem>
                  <MenuItem value={Languages.en}>
                    <Text>ENGLISH</Text>
                  </MenuItem>
                </TextField>
              </div>
            </div>

            <div>
              <div className="flex-1">
                <Text className="mb-1">DESCRIPTION</Text>
                <textarea
                  value={companyFormValues.description}
                  placeholder={getTranslation('DESCRIPTION')}
                  onChange={({ target: { value } }) => handleOnChangeCompanyValues('description', value ?? '')}
                  className="w-full border-[1px] border-gray-600 rounded-md px-3 py-2"
                />
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center">
                <h2 className="text-2xl font-bold">
                  <Text>COMPANY_IMAGE</Text>
                </h2>
                {/* <label className="flex gap-2">
                  <input
                    type="checkbox"
                    className="h-5 w-5"
                    checked={companyFormValues.showOnlyBanner}
                    onChange={e => handleOnChangeCompanyValues('showOnlyBanner', e.target.checked)}
                  />
                  {getTranslation('SHOW_ONLY_BANNER')}
                </label> */}
              </div>

              <div className="flex flex-col md:flex-row items-center justify-center gap-4">
                <div className="flex-1">
                  <img
                    src={companyFormValues?.image ? companyFormValues?.image : DefaultImage}
                    alt="event"
                    className="object-cover"
                  />
                </div>
                <div className="flex flex-1 flex-col items-center justify-center">
                  <TextField
                    multiline
                    type="text"
                    name="image"
                    id="image-input"
                    value={companyFormValues.image}
                    className="w-full border-[1px] border-gray-600 rounded-md "
                    onChange={e => handleOnChangeCompanyValues('image', e.target.value)}
                    label={<Text simple>IMAGE_LINK</Text>}
                  />
                  <div className="basis-1/5 text-center">
                    <Text>OR</Text>
                  </div>
                  <Tooltip title="Load image">
                    <Button onClick={() => setAddModal(true)} startIcon={<CloudUploadRounded />}>
                      <Text simple>UPLOAD_FILE</Text>
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-bold">
                <Text>SOCIAL_LINKS</Text>
              </h2>
              <div className="flex gap-0 md:gap-6 flex-col md:flex-row mt-4">
                <TextField
                  margin="none"
                  id="name-input"
                  name="name"
                  label="Instagram"
                  type="text"
                  value={companyFormValues?.socialLinks?.instagram}
                  className="w-full border-[1px] border-gray-600 rounded-md "
                  onChange={e => handleSocialLinkChange(e, 'instagram')}
                />
                <TextField
                  margin="none"
                  id="name-input"
                  name="name"
                  label="Twitter"
                  type="text"
                  value={companyFormValues?.socialLinks?.twitter}
                  className="w-full border-[1px] border-gray-600 rounded-md"
                  onChange={e => handleSocialLinkChange(e, 'twitter')}
                />
                <TextField
                  margin="none"
                  id="name-input"
                  name="name"
                  label="Facebook"
                  type="text"
                  value={companyFormValues?.socialLinks?.facebook}
                  className="w-full border-[1px] border-gray-600 rounded-md "
                  onChange={e => handleSocialLinkChange(e, 'facebook')}
                />
              </div>
            </div>
          </div>
          {auth?.currentUser?.isOwner ? (
            <div className="flex flex-row mt-5 justify-end">
              <button className="bg-red-600 px-3 py-1 rounded-md mr-3 flex flex-row" onClick={onDeleteCompany}>
                <Text className="font-semibold" color="text-white">
                  DELETE
                </Text>
                <Delete className="text-white" />
              </button>
              <button
                className={`${hasCompanyChange ? 'bg-green-600' : 'bg-gray-400'} px-3 py-1 rounded-md flex flex-row`}
                onClick={onSubmitCompany}
              >
                <Text className="font-semibold" color="text-white">
                  SAVE
                </Text>
                <Check className="text-white" />
              </button>
            </div>
          ) : (
            <div className="primary flex flex-row mt-5 justify-end">
              <button onClick={onExitCompany} className={`px-3 py-1 rounded-md flex flex-row`}>
                <Text className="font-semibold" color="text-white">
                  EXIT
                </Text>
                <ExitToApp className="text-white ml-2" />
              </button>
            </div>
          )}
          {system.environment !== 'production' && auth?.currentUser?.isOwner && (
            <AvailableTickets
              company={company}
              getData={getData}
              getPricing={getPricing}
              setSubscriptionAccesses={setSubscriptionAccesses}
              setSubscriptionEvents={setSubscriptionEvents}
              subscription={subscription}
              subscriptionAccesses={subscriptionAccesses}
              subscriptionEvents={subscriptionEvents}
              user={user}
            />
          )}
        </section>

        <DropzoneDialog
          filesLimit={1}
          open={addModal}
          onSave={onLoadImage}
          maxFileSize={5000000}
          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
          onClose={() => setAddModal(false)}
          previewText={getTranslation('PREVIEW')}
          submitButtonText={getTranslation('SUBMIT')}
          cancelButtonText={getTranslation('CANCEL')}
          dialogTitle={getTranslation('UPLOAD_FILE')}
          dropzoneText={getTranslation('IMAGE_LOAD_DESCRIPTION')}
        />
        <ModalSuggestions isOpen={isModalSuggestionsOpen} onClose={() => setIsModalSuggestionsOpen(false)} />
        {isAdmin && <CompanyKeys company={company} getData={getData} />}
      </div>
    </>
  );
}

export default Settings;
