import { child, getDatabase, ref, update, push, get, set, remove, runTransaction, increment } from 'firebase/database';
import { IInvitation } from 'src/types/invitations.type';
import { ICompany, ISubscription } from 'src/types/company.type';
import { IGatewayToken, IToken } from 'src/types/token.type';
import PaymentMethods from 'src/types/payment.type';

export const createCompany = async (name: string, country: string, admin: object): Promise<string> => {
  try {
    const companyKey = push(child(ref(getDatabase()), `companies/`)).key;
    const updates: any = {};
    updates[`companies/${companyKey}/admins/`] = admin;

    const allCompaniesRef = ref(getDatabase(), 'companies/all');

    const response = await runTransaction(allCompaniesRef, allCompanies => {
      if (allCompanies) {
        let companyNumber = allCompanies.companyCount + 1;
        allCompanies[companyNumber] = { company: companyKey };

        allCompanies.companyCount = increment(1);
      }

      return allCompanies;
    });

    let firstCompany = false;
    if (!response.snapshot.val()) {
      firstCompany = true;
      await update(child(ref(getDatabase()), 'companies/all/1'), { company: companyKey });
      await update(child(ref(getDatabase()), 'companies/all/'), { companyCount: 1 });
    }
    updates[`companies/${companyKey}/data/`] = {
      name,
      country,
      id: companyKey,
      publicId: firstCompany ? 1 : response.snapshot.val().companyCount,
    };

    await update(child(ref(getDatabase()), '/'), updates);
    await update(child(ref(getDatabase()), `companies/${companyKey}`), { createdBy: Object.keys(admin)[0] });
    return companyKey as string;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getCompany = async (companyId: string): Promise<ICompany | null> => {
  const snapshot = await get(child(ref(getDatabase()), `companies/${companyId}`));
  if (!snapshot.exists()) return null;
  const company = snapshot.val();
  return { ...company.data, subscription: company.subscription };
};

export const getCompanyApiKeys = async (companyId: string): Promise<IToken[]> => {
  const snapshot = await get(child(ref(getDatabase()), `tokens/${companyId}`));
  const tokens: IToken[] = [];
  if (snapshot.exists()) {
    const tokensObject = snapshot.val();
    for (const key in tokensObject) {
      tokens.push({ id: key, ...tokensObject[key] });
    }
  }
  return tokens;
};

export const createInvitation = async (invitation: IInvitation): Promise<{ message: string; status: boolean }> => {
  try {
    await push(child(ref(getDatabase()), `invitations/${invitation.company}`), invitation);
    return { status: true, message: 'Invitation created successfully' };
  } catch (err: any) {
    return { status: false, message: 'An error occurred while creating an invitation' };
  }
};

export const updateCompanyInformation = async (company: ICompany, userId?: string): Promise<boolean> => {
  try {
    const updates: any = {};
    if (company.name) updates[`companies/${company.id}/data/name`] = company.name;
    updates[`companies/${company.id}/data/country`] = company.country;
    updates[`companies/${company.id}/data/image`] = company.image;
    updates[`companies/${company.id}/data/showOnlyBanner`] = true;
    if (company.language) updates[`companies/${company.id}/data/language`] = company.language;
    updates[`companies/${company.id}/data/description`] = company.description;
    if (company.socialLinks) updates[`companies/${company.id}/data/socialLinks`] = company.socialLinks;
    if (company.name && userId) updates[`users/${userId}/companies/${company.id}/name`] = company.name;
    await update(child(ref(getDatabase()), '/'), updates);
    return true;
  } catch (err: any) {
    return false;
  }
};

export const addTag = async (companyKey: string, tags: { [key: string]: string }): Promise<boolean> => {
  try {
    const allTags = Object.keys(tags);
    allTags.map(async key => {
      await set(child(ref(getDatabase()), `companies/${companyKey}/tags/${key}/`), tags[key]);
    });
    return true;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getAllTags = async (companyKey: string): Promise<any> => {
  const snapshot = await get(child(ref(getDatabase()), `companies/${companyKey}/tags`));
  if (!snapshot.exists()) return null;
  const tags = snapshot.val();
  return tags;
};

export const updateCompanySubscription = async (id: string, subscription?: ISubscription): Promise<boolean> => {
  try {
    if (!subscription) {
      await remove(child(ref(getDatabase()), `companies/${id}/subscription`));
      return true;
    }
    const updates: any = {};
    updates[`companies/${id}/subscription`] = subscription;
    await update(child(ref(getDatabase()), '/'), updates);
    return true;
  } catch (err: any) {
    return false;
  }
};

export const updatePaymentToken = async (tokenData: IGatewayToken): Promise<{ message: string; status: boolean }> => {
  try {
    await update(
      child(ref(getDatabase()), `companies/${tokenData.companyId}/data/payments/${tokenData.paymentMethod}`),
      {
        token: tokenData.token,
      },
    );
    return { status: true, message: 'Mercado pago token created successfully' };
  } catch (err: any) {
    return { status: false, message: 'An error occurred while creating an Mercado Pago token' };
  }
};
export const updatePaymentHasSecret = async (
  companyId: string,
  paymentMethod: PaymentMethods,
): Promise<{ message: string; status: boolean }> => {
  try {
    await update(child(ref(getDatabase()), `companies/${companyId}/data/payments/${paymentMethod}`), {
      hasSecret: true,
    });
    return { status: true, message: 'Mercado pago token created successfully' };
  } catch (err: any) {
    return { status: false, message: 'An error occurred while creating an Mercado Pago token' };
  }
};
