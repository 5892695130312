import React, { useState } from 'react';
import { Button, IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import Text from 'src/components/Text';

import { EventFormValues } from '../../EventForm';

export const SpecialConditions = ({
  formValues,
  setFormValues,
}: {
  formValues: EventFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<EventFormValues>>;
}) => {
  const [newCondition, setNewCondition] = useState('');

  const addNewCondition = () => {
    if (newCondition.length) {
      setFormValues(current => ({
        ...current,
        specialConditions: [...(current?.specialConditions || []), newCondition],
      }));
      setNewCondition('');
    }
  };

  const handleChangeCondition = (condition: string, index: number) => {
    if (condition.length)
      setFormValues(current => ({
        ...current,
        specialConditions: [
          ...(current?.specialConditions || []).map((value, i) => {
            if (i === index) return condition;
            return value;
          }),
        ],
      }));
  };

  const handleDeleteCondition = (index: number) => {
    setFormValues(current => ({
      ...current,
      specialConditions: [...(current?.specialConditions || []).filter((value, i) => i !== index)],
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addNewCondition();
    }
  };

  return (
    <section className="my-10">
      <h2 className="text-2xl font-bold">
        <Text>SPECIAL_CONDITIONS</Text>
        <h4 className="text-lg">
          <Text>SPECIAL_CONDITIONS_EXAMPLE</Text>
        </h4>
      </h2>
      <div className="flex flex-col gap-8 mt-5">
        <ul className="flex flex-col gap-4">
          {formValues?.specialConditions?.map((condition, i) => (
            <li className="w-full flex gap-2 md:gap-10" key={i}>
              <TextField
                margin="none"
                id={`condition-${i}-input`}
                name={`condition-${i}`}
                label="Condición"
                type="text"
                placeholder="Condición"
                value={condition}
                onChange={e => handleChangeCondition(e.target.value, i)}
                fullWidth
              />
              <IconButton
                color="error"
                onClick={() => handleDeleteCondition(i)}
                className="flex !justify-center p-0 !min-w-min"
              >
                <DeleteIcon />
              </IconButton>
            </li>
          ))}
        </ul>
        <div className="w-full flex gap-4 justify-end flex-col md:flex-row">
          <TextField
            className="w-full"
            margin="none"
            id="new-condition-input"
            name="new-condition"
            label="Condición"
            type="text"
            placeholder="Condición"
            value={newCondition}
            onChange={e => setNewCondition(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <Button
            onClick={addNewCondition}
            variant="contained"
            startIcon={<AddIcon />}
            color="success"
            className="px-5"
          >
            <Text className="text-gray-200">ADD</Text>
          </Button>
        </div>
      </div>
    </section>
  );
};
