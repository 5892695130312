import { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';

import Text from 'src/components/Text';

import { eventFormStyles } from '../../utils/event.utils';

export interface EventSocialLinkProps {
  initialValues: {
    socialLinks: {
      instagram: string;
      twitter: string;
      facebook: string;
    };
  };
  onFormChange: (formValues: EventSocialLinkProps['initialValues']) => void;
}

const useStyles = eventFormStyles;

const EventSocialLinks = ({ initialValues, onFormChange }: EventSocialLinkProps) => {
  const classes = useStyles();

  const [formValues, setFormValues] = useState(initialValues);

  const handleSocialLinkChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, social: string) => {
    setFormValues(current => ({
      ...current,
      socialLinks: {
        ...current.socialLinks,
        [social]: e.target.value,
      },
    }));
  };

  useEffect(() => {
    if (onFormChange) {
      onFormChange(formValues);
    }
  }, [formValues]);

  return (
    <section className="mt-10">
      <h2 className="text-2xl font-bold">
        <Text>SOCIAL_LINKS</Text>
      </h2>
      <Box className="flex gap-0 md:gap-6 flex-col md:flex-row">
        <TextField
          margin="none"
          id="name-input"
          name="name"
          label="Instagram"
          type="text"
          value={formValues.socialLinks.instagram}
          className={classes.input}
          onChange={e => handleSocialLinkChange(e, 'instagram')}
        />
        <TextField
          margin="none"
          id="name-input"
          name="name"
          label="Twitter"
          type="text"
          value={formValues.socialLinks.twitter}
          className={classes.input}
          onChange={e => handleSocialLinkChange(e, 'twitter')}
        />
        <TextField
          margin="none"
          id="name-input"
          name="name"
          label="Facebook"
          type="text"
          value={formValues.socialLinks.facebook}
          className={classes.input}
          onChange={e => handleSocialLinkChange(e, 'facebook')}
        />
      </Box>
    </section>
  );
};

export default EventSocialLinks;
