import { useEffect, useRef, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box, Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useLanguage } from 'src/contexts/language.context';
import Text from 'src/components/Text';
import Languages from 'src/constants/languages';
import system from 'src/constants/system';

import { eventFormStyles } from '../../utils/event.utils';

dayjs.extend(utc);

export interface EventGeneralInformationProps {
  initialValues: {
    private: boolean;
    name: string;
    allowToReadFrom: Date;
    date: Date;
    allowToReadTo: Date;
    description: string;
    eventLanguage?: Languages;
  };
  showError?: boolean;
  create?: boolean;
  onFormChange?: (formValues: EventGeneralInformationProps['initialValues']) => void;
  onFormStatusChange?: (formStatus: boolean) => void;
}

const useStyles = eventFormStyles;

export const EventGeneralInformation = ({
  initialValues,
  create,
  showError,
  onFormChange,
  onFormStatusChange,
}: EventGeneralInformationProps) => {
  const classes = useStyles();
  const { getTranslation } = useLanguage();

  const editorRef = useRef<any>(null);

  const [formValues, setFormValues] = useState(initialValues);
  const [isError, setIsError] = useState(false);

  const dateFormat = 'dd/MM/yyyy hh:mm a';

  useEffect(() => {
    validateFormValues();

    if (onFormChange) {
      onFormChange(formValues);
    }

    if (onFormStatusChange) {
      onFormStatusChange(!isError);
    }
  }, [formValues, isError]);

  const validateFormValues = () => {
    const requiredFields = ['name', 'date', 'allowToReadFrom', 'allowToReadTo'];

    const hasError = requiredFields.some(field => !(formValues as any)[`${field}`]);

    const error = hasError || !checkValidDates();

    setIsError(error);
  };

  const handleInputChange = (e: any) => {
    const inputType = e.target?.type;

    const value: { [key: string]: boolean | string } = {
      checkbox: e.target.checked,
      text: e.target.value,
    };

    const updatedFormValues = {
      ...formValues,
      [e.target.name]: inputType ? value[inputType] : e.target.value,
    };

    setFormValues(updatedFormValues);
  };

  const handleDateChange = (date: Date | null, name: string) => {
    const updatedFormValues = {
      ...formValues,
      [name]: date,
    };

    setFormValues(updatedFormValues);
  };

  const handleInputTextChange = (e: any) => {
    const updatedFormValues = {
      ...formValues,
      description: e.target.getContent(),
    };

    setFormValues(updatedFormValues);
  };

  const checkAllowToReadFrom = (): boolean => {
    let hasError = false;

    if (
      formValues.allowToReadFrom &&
      dayjs.utc(formValues.allowToReadFrom).toDate() > dayjs.utc(formValues.date).toDate()
    ) {
      hasError = true;
    }

    return hasError;
  };

  const checkAllowReadTo = (): boolean => {
    let hasError = false;

    if (
      formValues.allowToReadTo &&
      formValues.allowToReadTo < formValues.allowToReadFrom &&
      formValues.allowToReadFrom
    ) {
      hasError = true;
    }

    return hasError;
  };

  const checkEventDate = (): boolean => {
    const currentDate = dayjs.utc().toDate();

    let hasError = false;

    if (formValues.date && formValues.date < currentDate) {
      hasError = true;
    }

    return hasError;
  };

  const checkValidDates = () => {
    let validDates = true;

    if (checkAllowToReadFrom()) {
      validDates = false;
    }

    if (checkAllowReadTo()) {
      validDates = false;
    }

    if (create && checkEventDate()) {
      validDates = false;
    }

    return validDates;
  };

  return (
    <>
      <h2 className="text-2xl font-bold mb-4">
        <Text>CREATE_EVENT</Text>
      </h2>
      <section className="flex justify-end gap-8">
        <TextField
          type="text"
          name="eventLanguage"
          id="event-language"
          value={formValues.eventLanguage || Languages.es}
          className={` ${classes.input}`}
          onChange={handleInputChange}
          select
          label={<Text>EVENT_LANGUAGE</Text>}
        >
          <MenuItem value={Languages.es}>
            <Text>SPANISH</Text>
          </MenuItem>
          <MenuItem value={Languages.en}>
            <Text>ENGLISH</Text>
          </MenuItem>
        </TextField>
        <FormControlLabel
          style={{ width: '30%' }}
          control={<Checkbox name="private" checked={formValues.private} onChange={handleInputChange} />}
          label={getTranslation('PRIVATE_EVENT')}
        />
      </section>
      <section className="flex gap-0 md:gap-6 flex-col md:flex-row">
        <Box flex={1}>
          <TextField
            margin="none"
            id="name-input"
            name="name"
            label={
              <Text className="inline" simple>
                NAME
              </Text>
            }
            type="text"
            required
            value={formValues.name}
            className={classes.input}
            onChange={handleInputChange}
            error={showError && isError && !formValues.name}
            helperText={
              showError &&
              isError &&
              !formValues.name &&
              getTranslation('ATTRIBUTE_REQUIRED', { value: getTranslation('NAME') })
            }
          />
          <DateTimePicker
            label={
              <Text className="inline" simple>
                EVENT_DATE_TIME
              </Text>
            }
            format={dateFormat}
            value={formValues.date}
            className={classes.input}
            disablePast
            onChange={e => handleDateChange(e, 'date')}
            slotProps={{
              textField: {
                margin: 'none',
                id: 'date-input',
                name: 'date',
                className: classes.input,
                error: checkEventDate(),
                helperText: checkEventDate() && getTranslation('EVENT_DATE_AFTER_TODAY'),
              },
            }}
          />
        </Box>
        <Box flex={1} className="flex flex-col">
          <DateTimePicker
            className={classes.input}
            label={<Text simple>ALLOW_TO_READ_QR_FROM</Text>}
            value={formValues.allowToReadFrom}
            disablePast
            format={dateFormat}
            onChange={e => handleDateChange(e, 'allowToReadFrom')}
            slotProps={{
              textField: {
                margin: 'none',
                id: 'allow-to-read-from-input',
                name: 'allowToReadFrom',
                className: classes.input,
                error: checkAllowToReadFrom(),
                helperText: checkAllowToReadFrom() && getTranslation('READ_FROM_DATE_BEFORE_EVENT_DATE'),
              },
            }}
          />
          <DateTimePicker
            className={classes.input}
            label={<Text simple>ALLOW_TO_READ_QR_UNTIL</Text>}
            value={formValues.allowToReadTo}
            disablePast
            format={dateFormat}
            onChange={e => handleDateChange(e, 'allowToReadTo')}
            slotProps={{
              textField: {
                margin: 'none',
                id: 'allow-to-read-until-input',
                name: 'allowToReadTo',
                className: classes.input,
                error: checkAllowReadTo(),
                helperText: checkAllowReadTo() && getTranslation('READ_FROM_DATE_BEFORE_READ_TO'),
              },
            }}
          />
        </Box>
      </section>
      <section className="mt-10">
        <h2 className="text-2xl font-bold mb-4">
          <Text>EVENT_DESCRIPTION</Text>
        </h2>
        <Editor
          apiKey={system.tinyMCE_ApiKey}
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={formValues.description || '<p>Descripcion de evento.</p>'}
          onChange={handleInputTextChange}
          init={{
            height: 300,
            menubar: false,
            branding: false,
            plugins: 'lists link',
            link_default_target: '_blank',
            toolbar:
              'bold italic forecolor  | bullist numlist | link | alignleft aligncenter ' + 'alignright alignjustify',
          }}
        />
      </section>
    </>
  );
};
