import React, { forwardRef } from 'react';

import TextField from '@mui/material/TextField';
import AutocompleteMui from '@mui/material/Autocomplete';
import { useLanguage } from 'src/contexts/language.context';

interface IAutocompleteProps {
  value: string;
  options: string[];
  className?: string;
  onKeyDown: (e: any) => void;
  onChange: (value: string) => void;
  onInputChange: (e: any, value: string) => void;
}

const Autocomplete = forwardRef(function Autocomplete(props: IAutocompleteProps, ref) {
  const { getTranslation } = useLanguage();
  const { value, className, onChange, onInputChange, onKeyDown, options } = props;

  return (
    <AutocompleteMui
      freeSolo
      ref={ref}
      id="tags"
      clearOnBlur
      value={value}
      options={options}
      className={className}
      onKeyDown={onKeyDown}
      onInputChange={onInputChange}
      onChange={(event: any, newValue: string | null) => onChange(newValue || '')}
      renderInput={params => <TextField {...params} label={getTranslation('TAGS')} />}
    />
  );
});

export default Autocomplete;
