import { FC } from 'react';

import { EventCustomField } from 'src/types/event.type';

import { FieldAccordion } from './FieldAccordion';

interface Props {
  customfields: EventCustomField[];
  OnChangeCustomFields: (customfields: EventCustomField[]) => void;
}

export const AcorrdionList: FC<Props> = ({ customfields, OnChangeCustomFields }) => {
  const handleOnChange = (updatedField: EventCustomField, index: number) => {
    OnChangeCustomFields(
      customfields.map((customField, i) => {
        if (i === index) return updatedField;
        return customField;
      }),
    );
  };

  const handleOnDelete = (index: number) => {
    OnChangeCustomFields(customfields.filter((customField, i) => i !== index));
  };

  return (
    <div className="flex flex-col gap-4">
      {customfields.map((field, index) => (
        <FieldAccordion
          field={field}
          key={field.name + index}
          onChange={field => handleOnChange(field, index)}
          onDelete={() => handleOnDelete(index)}
          uniquesCount={customfields.filter(field => field.validations?.includes('UNIQUE')).length}
        />
      ))}
    </div>
  );
};
