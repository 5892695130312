import { useEffect, useState } from 'react';

import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

import Text from 'src/components/Text';
import { EventAccessesType } from 'src/constants/events';
import Languages from 'src/constants/languages';
import { EventCustomField, IEvent } from 'src/types/event.type';

import {
  EventGeneralInformation,
  EventGeneralInformationProps,
} from './components/EventGeneralInformation/EventGeneralInformation';
import EventImage, { EventImageProps } from './components/EventImages/EventImage';
import EventSocialLinks, { EventSocialLinkProps } from './components/SocialLinks/EventSocialLink';
import EventTags, { EventTagsProps } from './components/EventTags/EventTags';
import { SpecialConditions } from './components/SpecialConditions/SpecialConditions';
import AccessCustomFields from './components/AccessCustomFields';
import EventMap from '../Map/EventMap';

import {
  createEventFormValue,
  createEventGeneralInformationValue,
  createEventImagesValue,
  createEventSocialLinksValues,
  createEventTagsValues,
} from './utils/event.utils';

dayjs.extend(utc);

export interface EventFormValues {
  name: string;
  private?: boolean;
  location?: string | null;
  place?: string | null;
  date: Date;
  image: string;
  imageSmall: string;
  mobileImage: string;
  description?: string;
  allowToReadFrom?: Date | null;
  allowToReadTo?: Date | null;
  accessesId?: string | null;
  type?: keyof typeof EventAccessesType;
  tags: { [key: string]: string };
  specialConditions?: string[];
  tempMobileImage?: File;
  tempDesktopImage?: File;
  socialLinks: {
    instagram: string;
    twitter: string;
    facebook: string;
  };
  eventLanguage?: Languages;
  optionalValidations?: { [key: string]: string };
  eventCustomFields: EventCustomField[];
}

interface EventFormProps {
  initialValues?: IEvent | undefined;
  showError: boolean;
  onFormChange: (formValues: EventFormValues) => void;
  onFormStatusChange: (formStatus: boolean) => void;
}

const EventForm = ({ initialValues, showError, onFormChange, onFormStatusChange }: EventFormProps) => {
  const defaultValues: EventFormValues = createEventFormValue(initialValues || ({} as IEvent));

  const [formValues, setFormValues] = useState<EventFormValues>(defaultValues);
  const [formStatus, setFormStatus] = useState<boolean>(false);

  const [generalInformation, setGeneralInformation] = useState<EventGeneralInformationProps['initialValues']>();
  const [image, setImage] = useState<EventImageProps['initialValues']>();
  const [socialLinks, setSocialLinks] = useState<EventSocialLinkProps['initialValues']>();
  const [tags, setTags] = useState<EventTagsProps['initialValues']>();

  const handleFormStatusChange = (status: boolean) => {
    setFormStatus(status);
  };

  const handleFormValuesChange = (field: string, value: any) => {
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  useEffect(() => {
    const values = {
      ...formValues,
      ...generalInformation,
      ...image,
      ...socialLinks,
      ...tags,
    };

    onFormChange(values);
    onFormStatusChange(formStatus);
  }, [formValues, generalInformation, image, socialLinks, tags, formStatus]);

  return (
    <>
      {/* General Information */}
      <EventGeneralInformation
        initialValues={createEventGeneralInformationValue(initialValues || ({} as IEvent))}
        onFormChange={setGeneralInformation}
        onFormStatusChange={handleFormStatusChange}
        showError={showError}
      />

      {/* Special Conditions */}
      <SpecialConditions formValues={formValues} setFormValues={setFormValues} />

      {/* EventMap */}
      {/* TODO: Mover esto a un componente */}
      <section className="mt-10">
        <h2 className="text-2xl font-bold">
          <Text>LOCATION</Text>
        </h2>
        <EventMap values={formValues} handleInputChange={handleFormValuesChange}></EventMap>
      </section>

      {/* EventImage */}
      <EventImage initialValues={createEventImagesValue(initialValues || ({} as IEvent))} onFormChange={setImage} />

      {/* Access Custom Field */}
      <AccessCustomFields
        eventCustomField={formValues.eventCustomFields}
        onChange={fields => handleFormValuesChange('eventCustomFields', fields)}
      />

      {/* SocialLinks */}
      <EventSocialLinks
        initialValues={createEventSocialLinksValues(initialValues || ({} as IEvent))}
        onFormChange={setSocialLinks}
      />

      {/* EventTags */}
      <EventTags initialValues={createEventTagsValues(initialValues || ({} as IEvent))} onFormChange={setTags} />
    </>
  );
};

export default EventForm;
