import { IEvent } from 'src/types/event.type';
import { EventFormValues } from '../EventForm';
import Languages from 'src/constants/languages';
import DefaultImage from 'src/assets/images/events/default.png';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { EventGeneralInformationProps } from '../components/EventGeneralInformation/EventGeneralInformation';
import { EventImageProps } from '../components/EventImages/EventImage';
import { EventSocialLinkProps } from '../components/SocialLinks/EventSocialLink';
import { EventTagsProps } from '../components/EventTags/EventTags';
import { makeStyles } from '@mui/styles';

dayjs.extend(utc);

export const createEventFormValue = (event: IEvent): EventFormValues => {
  return {
    name: event?.name || '',
    private: event.private,
    date: event?.date || dayjs().add(2, 'day').toDate(),
    image: event?.image || DefaultImage,
    imageSmall: event?.imageSmall || DefaultImage,
    mobileImage: event?.mobileImage || DefaultImage,
    description: event?.description || '',
    location: event?.location || '',
    place: event?.place || '',
    allowToReadFrom: event?.allowToReadFrom || dayjs().add(2, 'day').toDate(),
    allowToReadTo: event?.allowToReadTo || dayjs().add(2, 'day').toDate(),
    accessesId: event?.accessesId || null,
    tags: event?.tags || {},
    socialLinks: event?.socialLinks || {
      facebook: '',
      instagram: '',
      twitter: '',
    },
    specialConditions: event?.specialConditions || [],
    eventLanguage: event?.eventLanguage || Languages.es,
    optionalValidations: event?.optionalValidations || {},
    eventCustomFields: event?.eventCustomFields || [{ name: 'email', type: 'email', validations: ['REQUIRED'] }],
  };
};

export const createEventGeneralInformationValue = (event: IEvent): EventGeneralInformationProps['initialValues'] => {
  return {
    private: event?.private as boolean,
    name: event?.name || '',
    date: event?.date || dayjs().add(2, 'day').toDate(),
    allowToReadFrom: event?.allowToReadFrom || dayjs().add(2, 'day').toDate(),
    allowToReadTo: event?.allowToReadTo || dayjs().add(2, 'day').toDate(),
    description: event?.description || '',
    eventLanguage: event?.eventLanguage || Languages.es,
  };
};

export const createEventImagesValue = (event: IEvent): EventImageProps['initialValues'] => {
  return {
    image: event?.image || DefaultImage,
    imageSmall: event?.imageSmall || DefaultImage,
    mobileImage: event?.mobileImage || DefaultImage,
  };
};

export const createEventSocialLinksValues = (event: IEvent): EventSocialLinkProps['initialValues'] => {
  return {
    socialLinks: {
      facebook: event?.socialLinks?.facebook || '',
      instagram: event?.socialLinks?.instagram || '',
      twitter: event?.socialLinks?.twitter || '',
    },
  };
};

export const createEventTagsValues = (event: IEvent): EventTagsProps['initialValues'] => {
  return {
    tags: event?.tags || {},
  };
};

export const eventFormStyles = makeStyles(() => ({
  input: {
    width: '100%',
    marginTop: '20px !important',
    marginBottom: '16px !important',
    '>div': {
      width: '100%',
    },
  },
  error: {
    color: '#d32f2f',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: '400',
    fontSize: '0.75rem',
    lineHeight: '1.66',
    letterSpacing: '0.03333em',
    textAlign: 'left',
    marginTop: '3px',
    marginRight: '14px',
    marginBottom: '0',
    marginLeft: '14px',
  },
}));
