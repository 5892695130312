import React from 'react';
import { Close } from '@mui/icons-material';

interface ITagProps {
  key?: string;
  value: string;
  color: string;
  onRemove?: (value: string) => void;
}

export default function Tag({ value, color, onRemove }: ITagProps) {
  return (
    <div
      key={color}
      style={{ background: `${color}` }}
      className="text-white px-2 py-1 rounded-full flex flex-row items-center"
    >
      {value}
      {onRemove && (
        <span
          onClick={() => onRemove(value)}
          className="transition-all duration-300 rounded-full text-sm hover:bg-gray-300 hover:text-gray-50 hover:bg-opacity-60 ml-1 cursor-pointer w-5 h-5 flex justify-center items-center"
        >
          <Close fontSize="inherit" className="p-0" />
        </span>
      )}
    </div>
  );
}
