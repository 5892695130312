import { FC, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
} from '@mui/material';

import { EventCustomField } from 'src/types/event.type';
import useStore from 'src/store';
import { useLanguage } from 'src/contexts/language.context';

interface Props {
  field: EventCustomField;
  onChange: (updatedField: EventCustomField) => void;
  onDelete: () => void;
  uniquesCount: number;
}
const INVALID_PROPERTY_NAMES = ['document', 'ticket', 'ticket_buy_on', 'price', 'email'];

export const FieldAccordion: FC<Props> = ({ field, onChange, onDelete, uniquesCount }) => {
  const showMessage = useStore.use.showMessage();
  const { getTranslation } = useLanguage();

  const [temporalField, setTemporalField] = useState<EventCustomField>(field);

  const fieldValidations = useMemo(() => {
    const validations = ['REQUIRED'];

    if (uniquesCount < 2 || field.validations?.includes('UNIQUE')) validations.push('UNIQUE');

    switch (field.type) {
      case 'date':
        break;
      case 'number':
        break;
      case 'text':
        break;

      default:
        break;
    }

    return validations;
  }, [field.type, uniquesCount, field.validations]);

  const handleOnSave = () => {
    if (temporalField.validations?.includes('UNIQUE') && uniquesCount >= 2)
      return showMessage('No puede haber mas de 2 campos únicos', 'error');
    onChange(temporalField);
  };

  const handleOnChange = (field: string, value: any) => {
    if (field === 'name') {
      if (INVALID_PROPERTY_NAMES.includes(value)) return showMessage('Nombre de propiedad reservado', 'error');

      if (/\s|[.[\]$#/]/.test(value as string))
        return showMessage('No deben haber caracteres especiales o espacios en el nombre', 'error');

      setTemporalField(current => ({ ...current, [field]: value as string }));
    } else {
      setTemporalField(current => ({ ...current, [field]: value }));
    }
  };

  const handleValidationChange = (value: string, checked: boolean) => {
    if (checked) {
      const newValue =
        value === 'UNIQUE' && !temporalField.validations?.includes('REQUIRED') ? ['REQUIRED', 'UNIQUE'] : [value];

      setTemporalField(current => ({ ...current, validations: [...(current.validations || []), ...newValue] }));
    } else {
      setTemporalField(current => ({
        ...current,
        validations: current.validations?.filter(validation => validation !== value) || [],
      }));
    }
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            '.MuiAccordionSummary-content': {
              justifyContent: 'space-between',
            },
          }}
        >
          <Typography>{field.name}</Typography>
          {temporalField.name !== 'email' && (
            <IconButton
              color="error"
              onClick={onDelete}
              className="float-right flex !justify-center p-0 !min-w-min"
              type="button"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex gap-4">
            <TextField
              id="outlined-basic"
              label={getTranslation('NAME')}
              variant="outlined"
              value={temporalField.name}
              fullWidth
              onChange={e => handleOnChange('name', e.target.value)}
              disabled={temporalField.name === 'email'}
            />

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{getTranslation('TYPE')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={temporalField.type}
                label={getTranslation('TYPE')}
                onChange={e => handleOnChange('type', e.target.value)}
                disabled={temporalField.name === 'email'}
              >
                <MenuItem value={'text'}>{getTranslation('TEXT')}</MenuItem>
                <MenuItem value={'number'}>{getTranslation('NUMBER')}</MenuItem>
                <MenuItem value={'date'}>{getTranslation('DATE')}</MenuItem>
                <MenuItem value={'email'}>{getTranslation('EMAIL')}</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={!!temporalField?.dontShowOnQRRead}
                  onChange={e => handleOnChange('dontShowOnQRRead', e.target.checked)}
                />
              }
              label={getTranslation('DONT_SHOW_ON_QR_READ')}
            />
          </div>

          <div className="mt-4">
            <p className="text-lg">{getTranslation('VALIDATIONS')}</p>

            <FormGroup className="!grid grid-cols-1 md:grid-cols-2">
              {fieldValidations.map((validation, i) => {
                return (
                  <FormControlLabel
                    key={validation}
                    control={
                      <Checkbox
                        defaultChecked={temporalField.validations?.includes(validation)}
                        onChange={e => handleValidationChange(validation, e.target.checked)}
                      />
                    }
                    label={getTranslation(validation)}
                    disabled={temporalField.name === 'email' && ['REQUIRED', 'UNIQUE'].includes(validation)}
                  />
                );
              })}
            </FormGroup>
          </div>

          <AccordionActions>
            <button type="button" className="py-2 px-4 bg-green-600 rounded  text-white" onClick={handleOnSave}>
              {getTranslation('SAVE')}
            </button>
          </AccordionActions>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
