import React, { ReactNode, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from 'src/components/Loading';
import { useAuth } from 'src/contexts/auth.context';
import { PrivateRoute, AdminRoute } from 'src/components/PrivateRoute/PrivateRoute';
import EventFormContainer from 'src/components/Events/EventForm/EventFormContainer';

const Home = React.lazy(() => import(/*webpackChunkName: "Home" */ 'src/components/Home/Home'));
const Landing = React.lazy(() => import(/*webpackChunkName: "Landing" */ 'src/components/Landing'));
const Faq = React.lazy(() => import(/*webpackChunkName: "Faq" */ 'src/screens/faqs'));
const Contact = React.lazy(() => import(/*webpackChunkName: "Contact" */ 'src/screens/contact'));
const NotFound = React.lazy(() => import(/*webpackChunkName: "NotFound" */ 'src/components/NotFound'));
const Error = React.lazy(() => import(/*webpackChunkName: "Error" */ 'src/components/Error/Error'));
const UserMgmt = React.lazy(() => import(/*webpackChunkName: "UserMgmt" */ 'src/components/UserMgmt'));
const SignIn = React.lazy(() => import(/*webpackChunkName: "SignIn" */ 'src/components/SignIn/SignIn'));
const SignUp = React.lazy(() => import(/*webpackChunkName: "SignUp" */ 'src/components/SignUp/SignUp'));
const UserList = React.lazy(() => import(/*webpackChunkName: "UserList" */ 'src/components/Users/UserList'));
const Settings = React.lazy(() => import(/*webpackChunkName: "Settings" */ 'src/components/Settings/Settings'));
const QrReader = React.lazy(() => import(/*webpackChunkName: "QrReader" */ 'src/components/QrReader/QrReader'));
const EventList = React.lazy(() => import(/*webpackChunkName: "EventList" */ 'src/components/Events/EventList'));
const EventForm = React.lazy(
  () => import(/*webpackChunkName: "EventForm" */ 'src/components/Events/EventForm/EventForm'),
);
const CompanyPage = React.lazy(() => import(/*webpackChunkName: "CompanyPage" */ 'src/components/Company/CompanyPage'));
const ResetPassword = React.lazy(
  () => import(/*webpackChunkName: "ResetPassword" */ 'src/components/ResetPassword/ResetPassword'),
);
const EventDetails = React.lazy(
  () => import(/*webpackChunkName: "EventDetails" */ 'src/components/Events/EventDetails/EventDetails'),
);
const CreateFirstCompany = React.lazy(
  () => import(/*webpackChunkName: "CreateFirstCompany" */ 'src/components/Company/CreateFirstCompany'),
);
const ChangeInvitePassword = React.lazy(
  () => import(/*webpackChunkName: "ChangePassword" */ 'src/components/Users/PasswordForm'),
);

interface RouteItem {
  key: string;
  path: string;
  element: ReactNode;
  subRoutes?: RouteItem[];
}

const eventAdminRoutes: RouteItem[] = [
  { key: '/dashboard/events/:id/update', path: '/dashboard/events/:id/update', element: <EventFormContainer /> },
  {
    key: '/dashboard/events/:id/update',
    path: '/dashboard/events/:id/payment',
    element: <EventFormContainer payment />,
  },
  { key: '/dashboard/events/:id/details', path: '/dashboard/events/:id/details', element: <EventDetails /> },
];

const eventCreateRoutes: RouteItem[] = [
  { key: '/dashboard/events/create', path: '/dashboard/events/create', element: <EventFormContainer create /> },
];

const dashboardRoutes: RouteItem[] = [
  { key: '/dashboard', path: '/', element: <Home /> },
  { key: '/dashboard/home', path: '/dashboard', element: <Home /> },
  { key: '/dashboard/:id', path: '/dashboard/:id', element: <QrReader /> },
  { key: '/dashboard/users', path: '/dashboard/users', element: <UserList /> },
  { key: '/dashboard/events', path: '/dashboard/events', element: <EventList /> },
  { key: '/dashboard/settings', path: '/dashboard/settings', element: <Settings /> },
  { key: '/dashboard/new-company', path: '/dashboard/new-company', element: <CompanyPage /> },
  { key: 'events/details', path: '/dashboard/events/:id', element: <AdminRoute />, subRoutes: eventAdminRoutes },
  { key: 'create/event', path: '/dashboard/events/create', element: <AdminRoute />, subRoutes: eventCreateRoutes },
];

const privateRoutes: RouteItem[] = [
  { key: '/faqs', path: '/faqs', element: <Faq /> },
  { key: '/contact', path: '/contact', element: <Contact /> },
  { key: '/not-found', path: '*', element: <NotFound /> },
  { key: '/dashboard', path: '/dashboard', element: <PrivateRoute />, subRoutes: dashboardRoutes },
  { key: '/company/create', path: '/company/create', element: <CreateFirstCompany /> },
  { key: '/error', path: '/error', element: <Error /> },
];

const publicRoutes: RouteItem[] = [
  { key: '/invited-user', path: '/invited-user', element: <ChangeInvitePassword /> },
  { key: '/not-found', path: '*', element: <NotFound /> },
  { key: '/', path: '/', element: <Landing /> },
  { key: '/login', path: '/login', element: <SignIn /> },
  { key: '/sign-up', path: '/sign-up', element: <SignUp /> },
  { key: '/reset-password', path: '/reset-password', element: <ResetPassword /> },
  { key: '/user-mgmt', path: '/user-mgmt', element: <UserMgmt /> },
  { key: '/error', path: '/error', element: <Error /> },
];

export default function Router() {
  const auth = useAuth();

  const renderRoute = (route: RouteItem): ReactNode => {
    if (!route.subRoutes) return <Route {...route} />;
    return route.subRoutes.map(renderRoute);
  };

  return (
    <Suspense fallback={<Loading isOpen />}>
      <Routes>{(auth?.isAuthenticated ? privateRoutes : publicRoutes).map(renderRoute)}</Routes>
    </Suspense>
  );
}
