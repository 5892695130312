import { FC, useEffect, useRef } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import Leaflet from 'leaflet';

import Text from 'src/components/Text';
import { Coordinates } from './EventMap';

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('src/assets/images/marker-icon-2x.png'),
  iconUrl: require('src/assets/images/marker-icon.png'),
  shadowUrl: require('src/assets/images/marker-shadow.png'),
});

interface MapProps {
  getAdress: (lat: any, lng: any) => Promise<void>;
  locationCoordenates: Coordinates;
  eventName?: string;
}

export const Map: FC<MapProps> = ({ getAdress, locationCoordenates, eventName }) => {
  const mapRef = useRef();

  useEffect(() => {
    const { current = {} } = mapRef;
    if (current === null) return;
    setTimeout(() => {
      //@ts-ignore:
      current.flyTo([locationCoordenates.latitud, locationCoordenates.longitud], 14, {
        duration: 3,
      });
    }, 200);
  }, [locationCoordenates]);

  return (
    <MapContainer
      tap={true}
      //@ts-ignore:
      ref={mapRef}
      center={[locationCoordenates.latitud, locationCoordenates.longitud]}
      zoom={12}
      scrollWheelZoom={false}
      style={{ height: '20vh', width: '40wh', zIndex: 0 }}
      //@ts-ignore:
      whenReady={(map: any) => {
        map.target.on('click', function (e: any) {
          const { lat, lng } = e.latlng;
          getAdress(lat, lng);
        });
      }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker //@ts-ignore:
        position={[locationCoordenates.latitud, locationCoordenates.longitud]}
      >
        <Popup>{eventName || <Text simple>EVENTS</Text>}</Popup>
      </Marker>
    </MapContainer>
  );
};
