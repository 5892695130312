import { child, getDatabase, ref, push, update, get, set, remove, runTransaction, increment } from 'firebase/database';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import { EventStatusEnum, IEvent, IEventPayment, ITicket } from 'src/types/event.type';
import { deleteAccessesList } from 'src/services/database/access.service';
import { deleteImage } from '../storage/event.service';
import slugify from 'slugify';

const defaultEvent: IEvent = {
  id: '',
  name: 'Test Event',
  date: new Date(),
  image:
    'https://firebasestorage.googleapis.com/v0/b/qr-manager-d2bbf.appspot.com/o/default%2Ffirst-event-default.png?alt=media&token=bbc158bb-4305-4c65-bcf5-87ea51791ed7',
};

export const createEvent = async (company: string, creator: string, event: IEvent = defaultEvent): Promise<string> => {
  try {
    const eventKey = push(child(ref(getDatabase()), `events/${company}/`)).key;
    if (eventKey) event.id = eventKey;

    const publicId = `${slugify(event.name, {
      lower: true,
      strict: true,
      trim: true,
    })}_${uuidv4().slice(0, 6)}`;

    const allEventsRef = ref(getDatabase(), 'events/all');
    await runTransaction(allEventsRef, allEvents => {
      // if (!allEvents) {
      // allEvents = { eventCount: 0 };
      // }

      if (allEvents) {
        allEvents[publicId] = { company, event: eventKey };
        // allEvents.eventCount = increment(1);
      }

      return allEvents;
    });

    await update(child(ref(getDatabase()), `events/${company}/${eventKey}/`), {
      ...event,
      publicId: publicId,
      date: event.date.toISOString(),
      createdAt: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
      createdBy: creator,
      status: EventStatusEnum['active'],
    });
    return eventKey as string;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const updateEvent = async (event: IEvent, company: string): Promise<void> => {
  try {
    const eventObject = {
      ...event,
      date: event.date.toISOString(),
      allowToReadFrom: event?.allowToReadFrom?.toISOString() || null,
      allowToReadTo: event?.allowToReadTo?.toISOString() || null,
    };
    return await update(child(ref(getDatabase()), `events/${company}/${event.id}/`), eventObject);
  } catch (err: any) {
    throw new Error(err);
  }
};

export const deleteEvent = async (companyId: string, eventId: string): Promise<boolean> => {
  try {
    await update(child(ref(getDatabase()), `events/${companyId}/${eventId}`), { status: EventStatusEnum['deleted'] });
    await remove(child(ref(getDatabase()), `/events/${companyId}/${eventId}/columns`));
    await remove(child(ref(getDatabase()), `/events/${companyId}/${eventId}/stats/schedules`));
    await deleteAccessesList(companyId, eventId);
    await deleteImage(companyId, eventId);
    return true;
  } catch (err: any) {
    return false;
  }
};

export const getEvent = async (companyId: string, eventId: string): Promise<IEvent | undefined> => {
  try {
    const snapshot = await get(child(ref(getDatabase()), `events/${companyId}/${eventId}`));
    if (!snapshot.exists()) return;
    const event = snapshot.val();

    const tokenSnapshot = await get(
      child(ref(getDatabase()), `companies/${companyId}/data/payments/mercadopago/token`),
    );
    let token;
    if (tokenSnapshot.exists()) {
      token = tokenSnapshot.val();
    }

    const response = {
      ...event,
      date: new Date(event.date),
      allowToReadFrom: new Date(event.allowToReadFrom),
      allowToReadTo: new Date(event.allowToReadTo),
      createdAt: new Date(event.createdAt),
      mercadopago: {
        token,
      },
    };

    if (!event?.allowToReadFrom) delete response.allowToReadFrom;
    if (!event?.allowToReadTo) delete response.allowToReadTo;
    if (!event?.createdAt) delete response.createdAt;
    return response;
  } catch (err: any) {
    return;
  }
};

export const getFirstScann = async (companyId: string, eventId: string): Promise<any | null> => {
  const snapshot = await get(child(ref(getDatabase()), `/events/${companyId}/${eventId}/firstScan`));
  if (!snapshot.exists()) return null;
  return dayjs(snapshot.val()).format('DD-MM-YYYY');
};

export const updatePaymentEvent = async (key: string, companyId: string, payment: IEventPayment): Promise<void> => {
  try {
    return await set(child(ref(getDatabase()), `events/${companyId}/${key}/payment`), payment);
  } catch (err: any) {
    throw new Error(err);
  }
};

export const addEventTickets = async (companyId: string, eventId: string, tickets: ITicket[]) => {
  try {
    const ticketsToAdd = [] as any;
    let alreadyAdded = [] as ITicket[];
    const snapshot = await get(child(ref(getDatabase()), `/events/${companyId}/${eventId}/tickets/`));
    if (snapshot.exists()) {
      alreadyAdded = snapshot.val();
    }
    tickets.forEach(async (ticket: ITicket) => {
      if (!Object.keys(alreadyAdded).includes(ticket.id!)) {
        const toAdd = {
          ...ticket,
          quantityAvailable: ticket.quantity,
          id: uuidv4(),
        };
        ticketsToAdd[toAdd.id] = toAdd;
      } else {
        ticketsToAdd[ticket.id!] = ticket;
      }
    });
    await set(child(ref(getDatabase()), `/events/${companyId}/${eventId}/tickets/`), ticketsToAdd);
  } catch (err: any) {
    throw new Error(err);
  }
};

export const editEventTicket = async (companyId: string, eventId: string, ticketToEdit: ITicket): Promise<boolean> => {
  if (!ticketToEdit.id) {
    return false;
  }

  try {
    let alreadyAdded = [] as ITicket[];
    const snapshot = await get(child(ref(getDatabase()), `/events/${companyId}/${eventId}/tickets/`));
    if (snapshot.exists()) {
      alreadyAdded = snapshot.val();
    } else {
      return false;
    }

    alreadyAdded[ticketToEdit.id as any] = ticketToEdit;

    await set(child(ref(getDatabase()), `/events/${companyId}/${eventId}/tickets/`), alreadyAdded);

    return true;
  } catch (err: any) {
    return false;
  }
};
