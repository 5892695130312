import { useEffect, useRef, useState } from 'react';

import Autocomplete from 'src/components/Events/Autocomplete';
import Tag from 'src/components/Events/Tag';
import Text from 'src/components/Text';
import { getRandomColor } from 'src/utils/colors';

import { eventFormStyles } from '../../utils/event.utils';

export interface EventTagsProps {
  initialValues: {
    tags: { [key: string]: string };
  };
  onFormChange: (formValues: EventTagsProps['initialValues']) => void;
}

const useStyles = eventFormStyles;

const EventTags = ({ initialValues, onFormChange }: EventTagsProps) => {
  const classes = useStyles();
  const autocompleteRef = useRef<any>(null);

  const [formValues, setFormValues] = useState(initialValues);
  const [options, setOptions] = useState<{ [key: string]: string } | null>(null);
  const [tagValue, setTagValue] = useState('');

  useEffect(() => {
    if (onFormChange) {
      onFormChange(formValues);
    }
  }, [formValues]);

  const onTagChange = (value: string) => {
    if (!value || (value && !value.trim())) return;
    onAddTag(value);
    setTagValue('');
    clearInput();
  };

  const clearInput = () => {
    const clearButton = autocompleteRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (clearButton) clearButton.click();
  };

  const onTagRemove = (tag: string) => {
    const tags = JSON.parse(JSON.stringify(formValues.tags));
    delete tags[tag];
    setFormValues({ ...formValues, tags });
  };

  const onTagKeyDown = (e: any) => {
    if (e.code !== 'Enter' || !tagValue.trim()) {
      if (e.code === 'Enter') {
        e.preventDefault();
        setTagValue('');
      }
      return;
    }
    onAddTag(tagValue);
    setTagValue('');
    e.preventDefault();
  };

  const onAddTag = (tag: string) => {
    tag = tag.trim();
    if (!formValues.tags[tag as any]) {
      let color = options && Object.keys(options).includes(tag) ? options[tag] : getRandomColor();
      setFormValues({
        ...formValues,
        tags: {
          ...formValues.tags,
          [tag]: color,
        },
      });
    }
  };

  return (
    <section className="mt-10">
      <h2 className="text-2xl font-bold">
        <Text>TAGS</Text>
      </h2>
      <Autocomplete
        value={tagValue}
        onChange={onTagChange}
        onKeyDown={onTagKeyDown}
        ref={autocompleteRef}
        className={classes.input}
        options={options ? Object.keys(options) : []}
        onInputChange={(e, value) => setTagValue(value)}
      />
      {Object.keys(formValues.tags).length > 0 && (
        <div className="flex gap-2 md:gap-5 my-5 flex-wrap">
          {Object.keys(formValues.tags).map((key, index) => (
            <Tag key={`${index}`} value={key} color={formValues.tags[key as any]} onRemove={onTagRemove} />
          ))}
        </div>
      )}
    </section>
  );
};

export default EventTags;
