import { FC } from 'react';

import Text from 'src/components/Text';
import { useLanguage } from 'src/contexts/language.context';
import { AcorrdionList } from './AccordionList';
import { EventCustomField } from 'src/types/event.type';

interface AccessCustomFieldsProps {
  eventCustomField: EventCustomField[];
  onChange: (field: EventCustomField[]) => void;
}

const AccessCustomFields: FC<AccessCustomFieldsProps> = ({ eventCustomField, onChange }) => {
  const { getTranslation } = useLanguage();

  const startCustomFieldCreation = () => {
    const newCustomField: EventCustomField = {
      name: 'New field',
      type: 'text',
      validations: [],
    };

    onChange([...eventCustomField, newCustomField]);
  };

  return (
    <section className="mt-10">
      <h2 className="text-2xl font-bold mb-4">
        <Text>EVENT_CUSTOM_FIELDS</Text>
      </h2>
      <AcorrdionList customfields={eventCustomField} OnChangeCustomFields={onChange} />
      <button
        type="button"
        onClick={startCustomFieldCreation}
        className="mt-4 p-2 w-full text-center text-white bg-blue-400 rounded shadow-md transition-all duration-200 hover:bg-blue-500"
      >
        {getTranslation('ADD_FIELD')}
      </button>
    </section>
  );
};

export default AccessCustomFields;
