import { DropzoneDialog } from 'react-mui-dropzone';
import { useEffect, useState } from 'react';
import { CloudUploadRounded } from '@mui/icons-material';
import { Box, Button, TextField, Tooltip } from '@mui/material';

import Text from 'src/components/Text';
import { useLanguage } from 'src/contexts/language.context';
import DefaultImage from 'src/assets/images/events/default.png';

import { eventFormStyles } from '../../utils/event.utils';

export interface EventImageProps {
  initialValues: {
    image: string;
    mobileImage: string;
    imageSmall: string;
    tempMobileImage?: File;
    tempDesktopImage?: File;
  };
  onFormChange: (formValues: EventImageProps['initialValues']) => void;
}

const useStyles = eventFormStyles;

const EventImage = ({ initialValues, onFormChange }: EventImageProps) => {
  const { getTranslation } = useLanguage();
  const classes = useStyles();

  const [formValues, setFormValues] = useState({
    image: initialValues.image || DefaultImage,
    mobileImage: initialValues.mobileImage || DefaultImage,
    imageSmall: initialValues.imageSmall || DefaultImage,
  });

  const [openDesktopImageModal, setOpenDesktopImageModal] = useState<boolean>(false);
  const [openMobileImageModal, setOpenMobileImageModal] = useState<boolean>(false);
  const [tempImageString, setTempImageString] = useState<string | undefined>();
  const [tempMobileImageString, setTempMobileImageString] = useState<string | undefined>();

  const [tempDesktopImage, setTempDesktopImage] = useState<File | undefined>();
  const [tempMobileImage, setTempMobileImage] = useState<File | undefined>();

  useEffect(() => {
    if (onFormChange) {
      const values = {
        ...formValues,
        tempDesktopImage,
        tempMobileImage,
      };

      onFormChange(values);
    }
  }, [formValues, tempDesktopImage, tempMobileImage]);

  const onLoadImage = async (e: any[], isMobileImage?: boolean) => {
    // if (!company) return;

    const reader = new FileReader();

    reader.onload = e => {
      if (isMobileImage) {
        setFormValues(current => ({
          ...current,
          mobileImage: e?.target?.result as string,
        }));
      } else {
        setFormValues(current => ({
          ...current,
          image: e?.target?.result as string,
          imageSmall: e?.target?.result as string,
        }));
      }
    };

    reader.readAsDataURL(e[0]);

    if (isMobileImage) setTempMobileImage(e[0]);
    else setTempDesktopImage(e[0]);

    setOpenDesktopImageModal(false);
    setOpenMobileImageModal(false);
  };

  return (
    <>
      <section className="mt-10">
        <h2 className="text-2xl font-bold">
          <Text>EVENT_IMAGE</Text>
        </h2>

        <h3 className="text-xl font-bold mt-4">
          <Text>DESKTOP_IMAGE</Text>
        </h3>
        <Box className="flex flex-col md:flex-row items-center justify-center gap-4">
          <Box className="flex-1">
            <img src={tempImageString ?? formValues.image ?? DefaultImage} alt="event" className="object-cover" />
          </Box>
          <Box className="flex flex-1 flex-col items-center justify-center">
            <p>{getTranslation('RECOMENDED')}: 1440 x 560 px</p>

            <TextField
              multiline
              type="text"
              id="image-input"
              value={tempImageString}
              className={` ${classes.input}`}
              onChange={e => {
                setTempDesktopImage(undefined);
                setTempImageString(e.target.value);
              }}
              label={<Text simple>IMAGE_LINK</Text>}
            />
            <Box className="basis-1/5 text-center">
              <Text>OR</Text>
            </Box>
            <Tooltip title="Load image" className="">
              <Button className="" onClick={() => setOpenDesktopImageModal(true)} startIcon={<CloudUploadRounded />}>
                <Text simple>UPLOAD_FILE</Text>
              </Button>
            </Tooltip>
          </Box>
        </Box>

        <h3 className="text-xl font-bold mt-4">
          <Text>MOBILE_IMAGE</Text>
        </h3>
        <Box className="flex flex-col md:flex-row items-center justify-center gap-4">
          <Box className="flex-1">
            <img
              src={tempMobileImageString ?? formValues.mobileImage ?? DefaultImage}
              alt="mobile event"
              className="object-cover"
            />
          </Box>
          <Box className="flex flex-1 flex-col items-center justify-center">
            <p>{getTranslation('RECOMENDED')}: 400 x 560 px</p>
            <TextField
              multiline
              type="text"
              id="mobile-image-input"
              value={tempMobileImageString}
              className={` ${classes.input}`}
              onChange={e => {
                setTempMobileImage(undefined);
                setTempMobileImageString(e.target.value);
              }}
              label={<Text simple>IMAGE_LINK</Text>}
            />
            <Box className="basis-1/5 text-center">
              <Text>OR</Text>
            </Box>
            <Tooltip title="Load image" className="">
              <Button className="" onClick={() => setOpenMobileImageModal(true)} startIcon={<CloudUploadRounded />}>
                <Text simple>UPLOAD_FILE</Text>
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </section>
      <DropzoneDialog
        filesLimit={1}
        open={openDesktopImageModal}
        onSave={files => onLoadImage(files)}
        maxFileSize={5000000}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        onClose={() => setOpenDesktopImageModal(false)}
        previewText={getTranslation('PREVIEW')}
        submitButtonText={getTranslation('SUBMIT')}
        cancelButtonText={getTranslation('CANCEL')}
        dialogTitle={getTranslation('UPLOAD_FILE')}
        dropzoneText={getTranslation('IMAGE_LOAD_DESCRIPTION')}
      />
      <DropzoneDialog
        filesLimit={1}
        open={openMobileImageModal}
        onSave={files => onLoadImage(files, true)}
        maxFileSize={5000000}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        onClose={() => setOpenMobileImageModal(false)}
        previewText={getTranslation('PREVIEW')}
        submitButtonText={getTranslation('SUBMIT')}
        cancelButtonText={getTranslation('CANCEL')}
        dialogTitle={getTranslation('UPLOAD_FILE')}
        dropzoneText={getTranslation('IMAGE_LOAD_DESCRIPTION')}
      />
    </>
  );
};

export default EventImage;
