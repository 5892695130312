import { Box, Button, Container } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useEventContext } from 'src/contexts/event.context';
import { useAuth } from 'src/contexts/auth.context';
import { createEvent, getEvent, updateEvent } from 'src/services/database/event.service';
import { addTag } from 'src/services/database/company.service';
import { uploadImage } from 'src/services/storage/event.service';
import { IEvent } from 'src/types/event.type';

import DefaultImage from 'src/assets/images/events/default.png';
import useStore from 'src/store';
import Text from 'src/components/Text';

import EventForm, { EventFormValues } from './EventForm';

dayjs.extend(utc);

interface EventFormContainerProps {
  create?: boolean;
  payment?: boolean;
}

const EventFormContainer = ({ create, payment }: EventFormContainerProps) => {
  const { id: eventId } = useParams();

  const [form, setForm] = useState<EventFormValues>({} as EventFormValues);
  const [formStatus, setFormStatus] = useState(false);
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();

  const auth = useAuth();
  const eventContext = useEventContext();

  const showMessage = useStore.use.showMessage();

  const company = auth?.currentCompany;

  const event = useMemo(
    () => auth?.currentUser?.events?.find(event => event.id === eventId) || undefined,
    [auth?.currentUser?.events, eventId],
  );

  useEffect(() => {
    if (!eventContext?.isEditable && !create && !payment) {
      navigate(`/dashboard/events/${event?.id}/details`);
    }
  }, []);

  const checkValidDates = () => {
    const currentDate = dayjs.utc().toDate();

    let validDates = true;
    if (form.allowToReadFrom && dayjs.utc(form.allowToReadFrom).toDate() > dayjs.utc(form.date).toDate()) {
      showMessage('READ_FROM_DATE_BEFORE_EVENT_DATE', 'error');
      validDates = false;
    }

    if (form.allowToReadFrom && form.allowToReadTo && form.allowToReadTo < form.allowToReadFrom) {
      showMessage('READ_FROM_DATE_BEFORE_READ_TO', 'error');
      validDates = false;
    }

    if (create && form.date < currentDate) {
      showMessage('EVENT_DATE_AFTER_TODAY', 'error');
      validDates = false;
    }
    if (event?.createdAt && event.createdAt > form.date) {
      showMessage('EVENT_DATE_PAST', 'error');
      validDates = false;
    }

    return validDates;
  };

  const onCreateEvent = async (): Promise<void> => {
    await addTags(form.tags);

    const eventForm = {
      ...form,
      allowToReadFrom: dayjs.utc(form.allowToReadFrom).toDate(),
      allowToReadTo: dayjs.utc(form.allowToReadTo).toDate(),
      date: dayjs.utc(form.date).toDate(),
    };

    delete eventForm.tempDesktopImage;
    delete eventForm.tempMobileImage;

    // All events are free for now, with unlimited accesses
    if (eventForm.type) {
      delete eventForm.type;
    }

    let eventId: string = '';

    // Create event
    try {
      eventId = await createEvent(company?.id as string, auth?.currentUser?.uid as string, {
        ...eventForm,
        id: '',
      });
    } catch (err: any) {
      console.error(err);
      navigate('/error');
    }

    //UploadImages
    const { imageUrl, imageSmallUrl, mobileUrl } = await uploadEventImages(eventId);

    // Update event with images
    try {
      await updateEvent(
        { ...eventForm, id: eventId, image: imageUrl, imageSmall: imageSmallUrl, mobileImage: mobileUrl },
        company?.id as string,
      );
    } catch (err: any) {
      console.error(err);
      navigate('/error');
    }

    if (auth?.currentUser) {
      await updateCurrentUserEvents(eventId);
    }

    navigate(`/dashboard/events`);
  };

  const updateCurrentUserEvents = async (eventId: string): Promise<void> => {
    if (auth?.currentUser) {
      const newEvent = await getEvent(company?.id as string, eventId);
      if (newEvent) {
        auth.setCurrentUser({
          ...auth.currentUser,
          events: [...(auth.currentUser.events as IEvent[]), newEvent],
        });
      }
    }
  };

  const addTags = async (tags: { [key: string]: string }) => {
    if (!company) return;
    if (!auth.currentUser?.events) return;

    try {
      await addTag(company.id, tags);
    } catch (err: any) {
      console.error(err);
      navigate('/error');
    }
  };

  const onUploadImage = async (eventId: string, file: File) => {
    const url = await uploadImage(String(company?.id), eventId, file, auth?.currentUser?.uid as string);
    return url;
  };

  const uploadEventImages = async (
    eventId: string,
  ): Promise<{ imageUrl: string; imageSmallUrl: string; mobileUrl: string }> => {
    let imageUrl: string = DefaultImage;
    let imageSmallUrl: string = DefaultImage;
    let mobileUrl: string = DefaultImage;

    // Upload images
    if (form.tempDesktopImage) {
      const url = await onUploadImage(eventId, form.tempDesktopImage);
      const timestamp = new Date().getTime();
      imageUrl = url.orig + '&' + timestamp;
      imageSmallUrl = url.small + '&' + timestamp;
    } else if (form.image) {
      imageUrl = form.image;
      imageSmallUrl = form.image;
    }

    if (form.tempMobileImage) {
      const url = await onUploadImage(eventId, form.tempMobileImage);
      const timestamp = new Date().getTime();
      mobileUrl = url.orig + '&' + timestamp;
    } else if (form.mobileImage) {
      mobileUrl = form.mobileImage;
    }

    return { imageUrl, imageSmallUrl, mobileUrl };
  };

  const onUpdateEvent = async (): Promise<void> => {
    try {
      if (event) {
        const { imageUrl, imageSmallUrl, mobileUrl } = await uploadEventImages(event.id);

        const eventForm: EventFormValues = {
          ...form,
          description: form.description,
          image: imageUrl,
          imageSmall: imageSmallUrl,
          mobileImage: mobileUrl,
          allowToReadFrom: dayjs.utc(form.allowToReadFrom).toDate(),
          allowToReadTo: dayjs.utc(form.allowToReadTo).toDate(),
          date: dayjs.utc(form.date).toDate(),
        };

        delete eventForm.tempDesktopImage;
        delete eventForm.tempMobileImage;

        await addTag(company?.id as string, form.tags);
        await updateEvent({ ...eventForm, id: event.id }, company?.id as string);
      }
    } catch (err: any) {
      console.error(err, 'error');
      navigate('/error');
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!company) return null;

    if (!auth.currentUser?.events) return;

    if (!formStatus || !checkValidDates()) {
      setShowError(true);
      handleScrollToTop();
      return;
    }

    try {
      if (create) {
        await onCreateEvent();
      } else {
        await onUpdateEvent();
      }

      navigate(`/dashboard/events`);
    } catch (err: any) {
      console.error(err);
      navigate('/error');
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!company) return null;

  return (
    <Container component="main" maxWidth="md" className="bg-white md:rounded-md md:shadow-md md:my-10 p-0">
      <div className="p-10">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <EventForm
              initialValues={event}
              onFormChange={setForm}
              onFormStatusChange={setFormStatus}
              showError={showError}
            />
            <div className="mt-5 grid grid-cols-2">
              <div>
                <Button variant="contained" color="info" onClick={() => navigate(-1)}>
                  <Text simple>BACK</Text>
                </Button>
              </div>
              <div className="text-right">
                <Button variant="contained" color="success" type="submit">
                  <Text simple>SUBMIT</Text>
                </Button>
              </div>
            </div>
          </Box>
        </LocalizationProvider>
      </div>
    </Container>
  );
};

export default EventFormContainer;
